import { Component, OnInit } from "@angular/core";

@Component({
  // tslint:disable-next-line:quotemark
  selector: "app-flat-profile",
  templateUrl: "./flat-profile.component.html",
  styleUrls: ["./flat-profile.component.scss"]
})
export class FlatProfileComponent {
  public iconMapping: { [key: string]: string } = {
    [AttributeKey.LOCATION]: "fas fa-map-marked-alt",
    [AttributeKey.SIZE]: "fas fa-ruler-horizontal",
    [AttributeKey.SLEEPING_ROOMS]: "fas fa-door-open",
    [AttributeKey.BATHROOMS]: "fas fa-bath",
    [AttributeKey.BEDS]: "fas fa-bed"
  };
  public attributes: Array<Attribute> = [
    {
      key: AttributeKey.LOCATION,
      displayText: "Ort",
      value: "Hinter der Neustadt 13, 25813 Husum"
    },
    {
      key: AttributeKey.SIZE,
      displayText: "Größe",
      value: "30 qm"
    },
    {
      key: AttributeKey.SLEEPING_ROOMS,
      displayText: "Schlafzimmer",
      value: "1x"
    },
    {
      key: AttributeKey.BATHROOMS,
      displayText: "Badezimmer",
      value: "1x"
    },
    {
      key: AttributeKey.BEDS,
      displayText: "Betten",
      value: "1x Doppelbett"
    }
  ];

  public paragraphs: Array<string> = [
    "Die Wohnung liegt in einer ruhigen, zentralen Lage etwa 5 Gehminuten vom Stadtkern entfernt." +
      " Supermarkt incl. Bäcker und Schlachter sind 3 Gehminuten entfernt." +
      " Viele Restaurants und Gaststätten mit Fisch, regionalen und internationalen Gerichten finden Sie in der Nähe." +
      " Bis zum Nordseestrand sind es ca. 1,5 Kilometer.",
    "Die Wohnung ist circa 30qm groß und besteht aus einem Wohnraum mit Einbauküche, einem separaten Schlafzimmer, einer Dusche, und einem WC." +
      " Sie ist weiterhin mit einer Terrasse und Gartenmöbeln ausgestattet. Bettwäsche, Handtücher und Endreinigung sind im Mietpreis von 53,- EUR/Tag enthalten."
  ];
}

export interface Attribute {
  key: AttributeKey;
  displayText: string;
  value: string | number;
}

export enum AttributeKey {
  LOCATION = "LOCATION",
  SIZE = "SIZE",
  SLEEPING_ROOMS = "SLEEPING_ROOMS",
  BATHROOMS = "BATHROOMS",
  BEDS = "BEDS"
}
