<div class="back-button-area">
  <div class="back-button" routerLink="/main"><span>Zurück</span></div>
</div>
<div class="contact-page">
  <h3>Kontakt</h3>
  <div class="contact">
    <ul>
      <li>Tine's Ferienwohnung</li>
      <li>Hinter der Neustadt 13</li>
      <li>25813 Husum</li>
    </ul>
  </div>
  <h3>Buchungskontakt</h3>
  <div class="contact">
    <ul>
      <li>Fam. Beilfuß</li>
      <li>Hellweg 48 b</li>
      <li>45721 Haltern am See</li>
      <li>Tel.: 02364 / 69833</li>
      <li>Mobil: 0160 / 4508857</li>
    </ul>
  </div>

</div>
