<div class="container">
 <div
    class="img-container"
    *ngFor="let i of sliderArray; let select = index"
    [ngClass]="{ selected: select === selectedIndex }"
  >
    <img class="image" src="{{ i.img }}" alt="{{ i.alt }}" />
  </div>
  <div class="controls">
    <div class="back control" (click)="decrementImageIndex()">
      <i class="fas fa-angle-left"></i>
    </div>
    <div class="forward control" (click)="incrementImageIndex()">
      <i class="fas fa-angle-right"></i>
    </div>
  </div>

</div>

