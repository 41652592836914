import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-landing-area",
  templateUrl: "./landing-area.component.html",
  styleUrls: ["./landing-area.component.scss"]
})
export class LandingAreaComponent {
  constructor() {}

  scrollToElement($element): void {
    const element = document.querySelector("#" + $element);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
}
