<div class="booking" id="booking">
  <div class="form">
    <h2>Buchungsanfrage</h2>
    <p>Senden sie eine Mail mit folgenden Angaben an beilfuss@t-online.de</p>
    <div class="form-information">* notwendige Angaben</div>
    <div
      class="required input"
      [ngClass]="{
        error: nameInputControl.touched && nameInputControl.invalid
      }"
    >
      <input

        type="text"
        placeholder="Ihren Namen eingeben.."
        #nameInputControl="ngModel"
        required
        [(ngModel)]="name"
      />
      <div class="label">Name *</div>
    </div>
    <div
      class="required input"
      [ngClass]="{
        error: mailInputControl.touched && mailInputControl.invalid
      }"
    >
      <input

        type="text"
        placeholder="Ihre E-Mailadresse eingeben.."
        #mailInputControl="ngModel"
        [(ngModel)]="mail"
      />
      <div class="label">E-Mail</div>
    </div>
    <div
      class="required input"
      [ngClass]="{
        error: phoneInputControl.touched && phoneInputControl.invalid
      }"
    >
      <input

        type="text"
        placeholder="Telefonnummer eingeben.."
        #phoneInputControl="ngModel"
        required
        [(ngModel)]="phone"
      />
      <div class="label">Telefonnummer *</div>
    </div>
    <div
      class="required input"
      [ngClass]="{
        error: dateInputControl.touched && dateInputControl.invalid
      }"
    >
      <input

        type="text"
        placeholder="Präferierten Buchungszeitraum eingeben.."
        #dateInputControl="ngModel"
        required
        [(ngModel)]="date"
      />
      <div class="label">Buchungszeitraum *</div>
    </div>
    <div
      class="input"
      [ngClass]="{
        error: dateInputControl.touched && dateInputControl.invalid
      }"
    >
      <textarea type="text" placeholder="Fragen und Anmerkungen.."
      [(ngModel)]="notes"></textarea>
      <div class="label">Anmerkungen und Fragen</div>
    </div>
    <div class="action">
      <a
        (click)="generateMail()"
        class="clickable button"
        [class.disabled]="
          nameInputControl.invalid ||
          mailInputControl.invalid ||
          phoneInputControl.invalid ||
          dateInputControl.invalid
        "
      >
        <span>Anfragen</span>
      </a>
    </div>
  </div>
</div>
