import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ImageDataService } from "./image-data.service";

@Component({
  selector: "app-image-view",
  templateUrl: "./image-view.component.html",
  styleUrls: ["./image-view.component.scss"]
})
export class ImageViewComponent implements OnInit {
  constructor(private data: ImageDataService) {}

  public sliderArray = [];
  public selectedIndex = 0;
  public transform = 0;

  ngOnInit(): void {
    this.data
      .getData()
      .subscribe((result: Array<ImageMetaData>) => (this.sliderArray = result));
  }

  public incrementImageIndex() {
    this.selectedIndex =
      (this.selectedIndex + 1) % (this.sliderArray.length - 1);
  }
  public decrementImageIndex() {
    this.selectedIndex === 0
      ? (this.selectedIndex = this.sliderArray.length - 1)
      : (this.selectedIndex = this.selectedIndex - 1);
  }
}

export interface ImageMetaData {
  img: string;
  alt: string;
  text: string;
}
