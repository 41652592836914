import { Component } from "@angular/core";

@Component({
  selector: "app-booking",
  templateUrl: "./booking.component.html",
  styleUrls: ["./booking.component.scss"]
})
export class BookingComponent {
  public name: string;
  public mail: string;
  public phone: string;
  public date: string;
  public notes: string;

  private subjectDefault = "Buchungsanfrage Tine's Ferienwohnung";
  private br = "%0D%0A";

  public subject = this.subjectDefault;
  public body = "Buchungsanfrage Tine's Ferienwohnung";

  public updateMailTexts() {
    this.subject = this.subjectDefault + ` - ${this.date} `;
    this.body = `BUCHUNGSANFRAGE ${this.br}
    ${this.br}
    Ich (${
      this.name
    }) würde die Ferienwohnung Tine's Ferienwohnung gerne im folgenden Zeitraum: ${
      this.br
    }
    ${this.br}
    ${this.date} ${this.br}
    ${this.br}
     buchen.${this.br}
    ${this.br}
    Sie erreichen mich unter ${this.mail && this.mail.length ? this.mail + " oder " : ""}${
      this.phone
    }.${this.br}
    ${this.br}
    Weitere Anmerkungen:${this.br}
    ${this.br}
    ${this.notes}${this.br}
    ${this.br}
    Freundliche Grüße${this.br}
    ${this.name}
    `;
  }

  public generateMail(): void {
    this.updateMailTexts();
    const mailLink = `mailto:beilfuss@t-online.de?subject=${this.subject}&body=${this.body}`;
    window.location.href = mailLink;
  }
}
