import { ContactComponent } from './contact/contact.component';
import { MainPageComponent } from './main-page/main-page.component';
import { ImpressumComponent } from "./impressum/impressum.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LandingAreaComponent } from "./landing-area/landing-area.component";

const routes: Routes = [
  { path: "", redirectTo: "/main", pathMatch: "full" },
  { path: "main", component: MainPageComponent },
  { path: "impressum", component: ImpressumComponent },
  { path: "contact", component: ContactComponent },
  {path: '**', redirectTo: '/main'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
