import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

const URL = "../assets/img/image-slider-config.json";

@Injectable()
export class ImageDataService {
  constructor(private http: HttpClient) {}

  getData() {
    return this.http.get(URL);
  }
}
