<h2 id="flat">Die Wohnung</h2>
<div class="image-view">
  <app-image-view></app-image-view>
</div>
<div class="profile">
  <h3>Steckbrief</h3>
  <div class="profile-point" *ngFor="let attribute of attributes">
    <i class="{{ iconMapping[attribute.key] }} icon"></i>
    <div class="attribute">
      <span class="value">{{ attribute.value }}</span>
      <span class="description">{{ attribute.displayText }}</span>
    </div>
  </div>
  <h3>Beschreibung</h3>
  <p *ngFor="let paragraph of paragraphs">{{paragraph}}</p>
</div>

