<div class="landing-area">
  <div class="nav-bar">
    <ul class="page-sections-links">
      <li class="clickable" (click)="scrollToElement('flat')"><a>Wohnung</a></li>
      <li class="clickable" *ngIf="false" (click)="scrollToElement('flat')"><a>Urlaubsumgebung</a></li>
    </ul>
    <a class="clickable action button" (click)="scrollToElement('booking')">Jetzt anfragen!</a>
  </div>
  <div class="content">
    <div class="title">Moin, moin...</div>
    <div class="sub-title">Herzlich Willkommen in der Hafenstadt Husum</div>
    <a class="clickable action button" (click)="scrollToElement('booking')">Jetzt anfragen!</a>
  </div>
  <div class="arrow-down"><i class="fas fa-angle-double-down"></i></div>
</div>
