import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BookingComponent } from './booking/booking.component';
import { FlatProfileComponent } from "./flat-profile/flat-profile.component";
import { ImageDataService } from "./image-view/image-data.service";
import { ImageViewComponent } from "./image-view/image-view.component";
import { LandingAreaComponent } from "./landing-area/landing-area.component";
import { FooterComponent } from './footer/footer.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { MainPageComponent } from './main-page/main-page.component';
import { ContactComponent } from './contact/contact.component';


@NgModule({
  declarations: [
    AppComponent,
    LandingAreaComponent,
    FlatProfileComponent,
    ImageViewComponent,
    BookingComponent,
    FooterComponent,
    ImpressumComponent,
    MainPageComponent,
    ContactComponent
  ],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, FormsModule],
  providers: [ImageDataService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
